export default /**
eeeeeeeeeeeeeee *
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee */
() => ({
    loader: {
        width: '100%',
        height: '100%',
        textAlign: 'center'
    },
    message: {
        marginTop: '5px'
    },
    overlay: {
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        backgroundColor: 'rgba(255,255,255,0.8)',
        zIndex: '10000'
    },
    inline: {
        display: 'inline',
        '& .loader--inner': {
            display: 'inline'
        }
    },
    dialog: { /* inherits hehavior of regular loader */ }
})
