import React, { useCallback, useContext, useEffect, useState } from 'react'
import ProfileMenu from './ProfileMenu'

import Navigation from 'pdc-navigation'

import HelpPopup from './HelpPopup'
import { getValue } from 'remote-config-value'
import { useValue } from 'firebase-utils'

import {
    ControlPanelNavIcon,
    AppsNavIcon,
    SupportNavIcon,
    LogoutNavIcon,
    BillingIcon
} from 'pdc-svg-icons'
import {
    CallIcon as CallsNavIcon,
    TextSmIcon as MessagesNavIcon,
    FaxIcon,
    DocumentsIcon,
    UserIcon as ContactsIcon,
    UsersTwoIcon as PeopleNavIcon,
    VideoIcon as MeetingsNavIcon,
    SettingsIcon as SettingsNavIcon,
    SupportIcon as HelpNavIcon,
    VoicemailIcon as VoicemailNavIcon,
    SmsEnrollmentIcon as EnrollmentNavIcon,
    GlobeIcon,
    GlobeSimpleIcon,
    DollarSignIcon,
    BarGraphIcon,
    StorefrontIcon,
    OpenInNewTabIcon
} from 'svg-icons'
import { theme } from 'get-theme'
import { getPhoneCom, logout } from 'phonecom'
import Search from './Search'
import ConfirmModal from 'confirm-modal'
import { getFeatureEnabled } from 'feature-flag'
import { FeatureEventsContext, LeaveContext, LeaveLevel, ScreenSizeContext, ThemeContext } from 'providers'
import { useToggle } from 'hooks'
import AppsIcon from '@material-ui/icons/Apps'

interface Props {
    /**
     * The active app's name
     */
    currentAppName: string
    /**
     * Number of unseen calls
     */
    unreadCalls: number
    /**
     * Number of unseen messages
     */
    unreadMessages: number
    /**
     * Number of unseen voicemails
     */
    unreadVoicemails: number
    /**
     *  Number of unseen faxes
     */
    unreadFaxes: number
    /**
     * Sent form communicator
     */
    userInfo: any
    unreadPorting: any
    goTo: any
    /**
     * Function (path: string)
     */
    redirect: (redirectPath: string) => void
    /**
     * Does the current active app have a change?
     * If 'true' this will prevent the navigation to go away for example on click on other app.
     */
    appHasChange: boolean
    /**
     * Function called on discardChanges
     */
    discardChanges: () => void
    /**
     * React children
     */
    children
    /**
     * Contains info for IdentityArea
     */
    currentUser: any
    /**
     * Contains extensions' 'unread' info
     */
    inboxesData: any
    /**
     * Called on logout
     */
    logout: () => void
    /**
     * Called on extension switch
     */
    onSwitchExtension: (extension: any) => void
    /**
     *  Has info for the current extension
     */
    extension: any
    navigationShow: boolean
    toggleShowNavigation: (navigationShow: boolean) => void
    incompleteE911Count: number
}

const appTitles: { [key: string]: string } = {
    calls: 'Calls',
    enrollment: 'SMS Enrollment',
    messages: getValue('messages_tab_title'),
    voicemail: 'Voicemail',
    faxes: 'Fax',
    controlPanel: 'Control Panel',
    legacyControlPanel: 'Classic Control Panel',
    moreServices: 'More Services',
    configure: 'Configure',
    people: 'Contacts',
    'personal-settings': 'My settings',
    meetings: getValue('meetings_tab_title'),
    settings: 'Settings-w',
    apps: getValue('apps_marketplace_nav_title'),
    helpModal: 'Help',
    support: 'Support',
    logout: 'Logout',
    numbers: 'Numbers',
    'company-information': 'Company information',
    users: 'Users',
    phones: 'Phones',
    SMSRegistration: 'SMS registration',
    blockedNumbers: 'Blocked numbers',
    liveReceptionist: 'Receptionist Services',
    accountSettings: 'Account settings',
    businessHours: 'Business hours',
    sms: 'SMS logs',
    total: 'Total calls',
    types: 'Call types',
    traffic: 'Calls by date',
    summary: 'Call summary',
    'call-logs': 'Call logs',
    'per-number': 'Calls per number',
    'per-extension': 'Calls per extension',
    'geo-distribution': 'Calls geo distribution',
    statements: 'Statements',
    'bill-and-pay': 'Bill and Pay',
    'payment-methods': 'Payment information',
    'apps-marketplace': 'Store',
    'live-receptionist': 'Receptionist services',
    'account-settings': 'Account settings'
}

/***/
const CNavigation = (props: Props): JSX.Element => {
    const [setRefinedNavigationEnabled, refinedNavigationEnabled] = useState(Boolean(process.env.REACT_APP_REFINED_NAVIGATION_ENABLED))

    class ParentMenu {
        static readonly REPORT = { text: 'Reports', routeId: 'reportParent', testId: 'reportParent', icon: refinedNavigationEnabled ? BarGraphIcon : AppsIcon, children: [] }
        static readonly BILLING = { text: 'Billing', routeId: 'billingParent', testId: 'billingParent', icon: refinedNavigationEnabled ? DollarSignIcon : AppsIcon, children: [] }
        static readonly ADMINISTRATION = { text: 'Administration', routeId: 'administration', testId: 'administration', icon: refinedNavigationEnabled ? GlobeSimpleIcon : GlobeIcon, children: [] }
    }

    const navItemTitles = JSON.parse(useValue('communicator_nav_titles'))

    const [appBarLabel, setAppBarLabel] = useState('')
    const [showHelp, toggleShowHelp] = useToggle(false)
    const [searchFeature, setSearchFeature] = useState(false)
    const [videoFeature, setVideoFeature] = useState(false)
    const [routerConfig, setRouterConfig] = useState(null)
    const [configureTabLink, setConfigureTabLink] = useState('')
    const [moreServicesTabLink, setMoreServicesTabLink] = useState('')
    const [showMoreServicesTab, setShowMoreServicesTab] = useState(false)
    const [showSmsEnrollmenTab, setshowSmsEnrollmenTab] = useState(false)
    const [showAppsMarketplaceTab, setShowAppsMarketplaceTab] = useState(false)
    const [showCallsTab, setShowCallsTab] = useState(false)
    const [showMessagesTab, setShowMessagesTab] = useState(false)
    const [showVoicemailTab, setShowVoicemailTab] = useState(false)
    const [showFaxTab, setShowFaxTab] = useState(false)
    const [showPeopleTab, setShowPeopleTab] = useState(false)
    const [showMeetingsTab, setShowMeetingsTab] = useState(false)
    const [showSettingsTab, setShowSettingsTab] = useState(false)
    const [showConfigureTab, setShowConfigureTab] = useState(false)
    const [showHelpTab, setShowHelpTab] = useState(false)
    const [showControlPanelTab, setShowControlPanelTab] = useState(false)
    const [showStatementsTab, setShowStatementsTab] = useState(false)
    const [showOverviewTab, setShowOverviewTab] = useState(true)
    const [showPaymentMethodsTab, setShowPaymentMethodsTab] = useState(false)
    const [showNumbersTab, setShowNumbersTab] = useState(false)
    const [showLiveReceptionistTab, setShowLiveReceptionistTab] = useState(false)
    const [showUsersTab, setShowUsersTab] = useState(false)
    const [showPhonesTab, setShowPhonesTab] = useState(false)
    const [showSMSRegistrationTab, setShowSMSRegistrationTab] = useState(false)

    const [showSMSDeliveryStatusTab, setShowSMSDeliveryStatusTab] = useState(false)
    const [showContactInforamtionTab, setShowContactInforamtionTab] = useState(false)
    const [showTotalTab, setShowTotalTab] = useState(false)
    const [showTrafficTab, setShowTrafficTab] = useState(false)
    const [showSummaryTab, setShowSummaryTab] = useState(false)
    const [showCallLogsTab, setShowCallLogsTab] = useState(false)
    const [showPerNumberTab, setShowPerNumberTab] = useState(false)
    const [showPerExtensionTab, setShowPerExtensionTab] = useState(false)
    const [showCallTypesTab, setShowCallTypesTab] = useState<boolean>(false)
    const [showGeoDistributionTab, setShowGeoDistributionTab] = useState(false)
    const [showBlockedNumbersTab, setShowBlockedNumbersTab] = useState(false)
    const [showAccountSettingsTab, setShowAccountSettingsTab] = useState(false)
    const [showBusinessHoursTab, setShowBusinessHoursTab] = useState(false)

    const navItemsDependencyArr = [showMoreServicesTab, showSmsEnrollmenTab, showAppsMarketplaceTab, showCallsTab, showMessagesTab, showVoicemailTab, showFaxTab, showPeopleTab, showMeetingsTab, showSettingsTab, showConfigureTab, showHelpTab, showControlPanelTab, showNumbersTab, showUsersTab, showPhonesTab, showSMSRegistrationTab, showCallLogsTab, showSMSDeliveryStatusTab, showBlockedNumbersTab, showLiveReceptionistTab, showStatementsTab, showOverviewTab, showPaymentMethodsTab, showAccountSettingsTab, showBusinessHoursTab, showContactInforamtionTab]

    const [userType, setUserType] = useState('')
    const [userRole, setUserRole] = useState('')
    const [logoutTabLink, setLogoutTabLink] = useState('')
    const [discardChangesExtension, setDiscardChangesExtension] = useState(null)

    const screenSizeContext = useContext(ScreenSizeContext)
    const leaveContext = useContext(LeaveContext)
    const featureEventsContext = useContext(FeatureEventsContext)
    const themeContext = useContext(ThemeContext)

    const helpSectionRef = React.useRef<HTMLDivElement | null>(null)
    const appNameRef = React.useRef<string>(props.currentAppName)

    const checkPopout = useCallback(route => {
        if (route.link.indexOf('https') !== -1) {
            if (route.text === 'Logout') logout()
            else window.open(route.link, route.text)
            return true
        }
        return false
    }, [])

    const onMenuChange = useCallback(route => {
        if (!checkPopout(route)) setAppBarLabel(route.text)
        // analythics

        // SPA support for userpilot
        // https://docs.userpilot.com/article/59-installation-guide-for-single-page-applications-spas
        if (window.userpilot) window.userpilot.reload()

        featureEventsContext.pushEvent({
            event: 'PDC_Category_Change',
            appName: route.routeId.toLowerCase()
        })
    }, [])

    const extensionSelectHandler = useCallback(async extension => {
        if ((screenSizeContext.mobile || screenSizeContext.tablet) && props.navigationShow) props.toggleShowNavigation(false)
        if (props.appHasChange) return setDiscardChangesExtension(extension)
        const approveLeave = await leaveContext.leave(LeaveLevel.USER)
        if (approveLeave) props.onSwitchExtension(extension)
    }, [screenSizeContext, props.appHasChange, props.onSwitchExtension, leaveContext])

    const onHelpClick = () => {
        toggleShowHelp()
    }

    const getTabs = useCallback(() => {
        const hasUserId = Boolean(props.userInfo.user_id)
        const isBJCompanyExtensionSelected = Boolean(hasUserId && props.userInfo.extension.is_company) // eslint-disable-line no-unused-vars

        const callsTab = {
            testId: 'calls-tab',
            link: `/e${props.userInfo.extension_id}/calls/`,
            icon: CallsNavIcon,
            text: appTitles.calls,
            routeId: 'calls',
            enabled: !process.env.REACT_APP_CALLS_TAB_HIDDEN && showCallsTab,
            unread: props.unreadCalls,
            active: props.currentAppName === 'calls'
        }

        const smsEnrollmentTab = {
            testId: 'sms-enrollment-tab',
            link: `/e${props.userInfo.extension_id}/enrollment/`,
            icon: EnrollmentNavIcon,
            text: appTitles.enrollment,
            routeId: 'enrollment',
            enabled: userRole === 'account' && showSmsEnrollmenTab,
            active: props.currentAppName === 'enrollment'
        }

        const messageTab = {
            link: `/e${props.userInfo.extension_id}/messages`,
            icon: MessagesNavIcon,
            text: appTitles.messages,
            routeId: 'messages',
            enabled: !process.env.REACT_APP_MESSAGES_TAB_HIDDEN && showMessagesTab,
            unread: props.unreadMessages,
            active: props.currentAppName === 'messages',
            testId: 'messages-tab'
        }

        const voicemailTab = {
            link: `/e${props.userInfo.extension_id}/voicemail`,
            icon: VoicemailNavIcon,
            text: appTitles.voicemail,
            routeId: 'voicemail',
            enabled: !process.env.REACT_APP_VOICEMAIL_TAB_HIDDEN && showVoicemailTab,
            unread: props.unreadVoicemails,
            active: props.currentAppName === 'voicemail',
            testId: 'voicemail-tab'
        }

        const faxesTab = {
            link: `/e${props.userInfo.extension_id}/fax`,
            icon: refinedNavigationEnabled ? FaxIcon : DocumentsIcon,
            text: appTitles.faxes,
            routeId: 'fax',
            enabled: !process.env.REACT_APP_FAX_TAB_HIDDEN && showFaxTab,
            unread: props.unreadFaxes,
            active: props.currentAppName === 'faxes',
            testId: 'faxes-tab'
        }

        const controlPanelTab = {
            testId: 'control-panel-tab',
            routeId: userType === 'nxt' ? 'legacyControlPanel' : 'controlPanel',
            link: `${window.APP_CONFIG.cpBase}/voip/?_sid=${props.userInfo.cp_session_token}`,
            icon: ControlPanelNavIcon,
            text: userType === 'nxt' ? appTitles.legacyControlPanel : appTitles.controlPanel,
            enabled: (userRole === 'account' || (userRole === 'extension' && userType === 'classic')) && showControlPanelTab
        }

        const moreServicesTab = {
            testId: 'more-services-tab',
            routeId: 'moreServices',
            link: `${moreServicesTabLink}#access_token=${window?.V5PHONECOM?.cp_token}&token_type=Bearer`,
            icon: refinedNavigationEnabled ? OpenInNewTabIcon : ControlPanelNavIcon,
            text: appTitles.moreServices,
            enabled: showMoreServicesTab && userRole === 'account'
        }
        const configureTab = {
            testId: 'configure-tab',
            routeId: 'configure',
            link: configureTabLink,
            icon: refinedNavigationEnabled ? OpenInNewTabIcon : ControlPanelNavIcon,
            text: appTitles.configure,
            enabled: userRole === 'account' && hasUserId && showConfigureTab,
            unread: (props.unreadPorting === '1') ? '!' : ''
        }

        const peopleTab = {
            link: `/e${props.userInfo.extension_id}/people`,
            icon: refinedNavigationEnabled ? ContactsIcon : PeopleNavIcon,
            text: appTitles.people,
            routeId: 'people',
            enabled: showPeopleTab,
            active: props.currentAppName === 'people',
            testId: 'people-tab'
        }

        const meetTab = {
            testId: 'meet-tab',
            link: `/e${props.userInfo.extension_id}/meetings`,
            icon: MeetingsNavIcon,
            text: appTitles.meetings,
            routeId: 'meetings',
            enabled: videoFeature && showMeetingsTab,
            active: props.currentAppName === 'meetings'
        }

        const appsMarketPlaceTab = {
            link: `/e${props.userInfo.extension_id}/apps-marketplace`,
            icon: refinedNavigationEnabled ? StorefrontIcon : AppsIcon,
            text: appTitles.apps,
            routeId: 'appsMarketPlace',
            enabled: userRole !== 'extension' && showAppsMarketplaceTab,
            active: props.currentAppName === 'apps-marketplace',
            testId: 'appsMarketPlace-tab'
        }

        const personalSettingsTab = {
            link: `/e${props.userInfo.extension_id}/personal-settings`,
            icon: SettingsNavIcon,
            text: appTitles['personal-settings'],
            routeId: 'personal_settings',
            enabled: showSettingsTab,
            active: props.currentAppName === 'personal-settings',
            testId: 'personal-settings-tab'
        }

        const settingsTab = {
            link: `/e${props.userInfo.extension_id}/settings`,
            icon: SettingsNavIcon,
            text: appTitles.settings,
            routeId: 'webComSettings',
            enabled: !process.env.REACT_APP_SETTINGS_TAB_HIDDEN,
            active: props.currentAppName === 'settings'
        }

        const appsTab = {
            testId: 'apps-tab',
            link: theme.appsTabLink,
            icon: AppsNavIcon,
            text: appTitles.apps,
            routeId: 'apps',
            enabled: !process.env.REACT_APP_APPS_TAB_HIDDEN
        }

        const helpButton = {
            testId: 'help-button',
            link: theme.appsTabLink,
            icon: HelpNavIcon,
            text: appTitles.helpModal,
            routeId: 'help',
            enabled: showHelpTab,
            onClick: () => onHelpClick(),
            ref: helpSectionRef
        }

        const supportTab = {
            testId: 'support-tab',
            link: theme.supportTabLink,
            routeId: 'support',
            icon: SupportNavIcon,
            text: appTitles.support,
            enabled: !process.env.REACT_APP_SUPPORT_TAB_HIDDEN
        }

        const logoutTab = {
            testId: 'support-tab',
            routeId: 'logout',
            link: logoutTabLink,
            icon: LogoutNavIcon,
            text: appTitles.logout,
            enabled: !process.env.REACT_APP_LOGOUT_TAB_HIDDEN
        }

        const numbersTab = {
            link: `/e${props.userInfo.extension_id}/phone-numbers`,
            icon: AppsIcon,
            text: appTitles.number,
            routeId: 'numbers',
            enabled: (userType === 'nxt' && userRole === 'account') && showNumbersTab,
            active: props.currentAppName === 'numbers',
            testId: 'numbers-tab',
            unread: (props.unreadPorting === '1') ? '!' : '',
            parent: ParentMenu.ADMINISTRATION
        }
        const contactInforamtionTab = {
            link: `/e${props.userInfo.extension_id}/company-information`,
            icon: AppsIcon,
            text: appTitles['company-information'],
            routeId: 'company-information',
            enabled: (userType === 'nxt' && userRole === 'account') && showContactInforamtionTab,
            active: props.currentAppName === 'company-information',
            testId: 'company-information-tab',
            parent: ParentMenu.ADMINISTRATION
        }

        const usersTab = {
            link: `/e${props.userInfo.extension_id}/users`,
            icon: AppsIcon,
            text: appTitles.user,
            routeId: 'users',
            enabled: (userType === 'nxt' && userRole === 'account') && showUsersTab,
            active: props.currentAppName === 'users',
            testId: 'users-tab',
            parent: ParentMenu.ADMINISTRATION
        }

        const phonesTab = {
            link: `/e${props.userInfo.extension_id}/phones`,
            icon: AppsIcon,
            text: appTitles.phones,
            routeId: 'phones',
            enabled: ((userType === 'nxt' || userType === 'classic') && userRole === 'account') && showPhonesTab,
            active: props.currentAppName === 'phones',
            unread: props.incompleteE911Count,
            testId: 'phones-tab',
            parent: ParentMenu.ADMINISTRATION
        }

        const SMSRegistrationTab = {
            link: `/e${props.userInfo.extension_id}/sms-registration`,
            icon: AppsIcon,
            text: appTitles.SMSRegistration,
            routeId: 'SMSRegistration',
            enabled: ((userType === 'nxt' || userType === 'classic') && userRole === 'account') && showSMSRegistrationTab,
            active: props.currentAppName === 'SMSRegistration',
            testId: 'SMSRegistration-tab',
            parent: ParentMenu.ADMINISTRATION
        }

        const blockedNumbersTab = {
            link: `/e${props.userInfo.extension_id}/blocked-numbers`,
            icon: AppsIcon,
            text: appTitles.blockedNumbers,
            routeId: 'blockedNumbers',
            enabled: userType === 'nxt' && userRole === 'account' && showBlockedNumbersTab,
            active: props.currentAppName === 'blockedNumbers',
            testId: 'blockedNumbers-tab',
            parent: ParentMenu.ADMINISTRATION
        }

        const liveReceptionistTab = {
            link: `/e${props.userInfo.extension_id}/live-receptionist`,
            icon: AppsIcon,
            text: appTitles.liveReceptionist,
            routeId: 'live-receptionist',
            enabled: userRole === 'account' && showLiveReceptionistTab,
            active: props.currentAppName === 'live-receptionist',
            testId: 'live-receptionist-tab',
            parent: ParentMenu.ADMINISTRATION
        }

        const accountSettingsTab = {
            link: `/e${props.userInfo.extension_id}/account-settings`,
            icon: AppsIcon,
            text: appTitles.accountSettings,
            routeId: 'account-settings',
            enabled: (userType === 'nxt' && userRole === 'account') && showAccountSettingsTab,
            active: props.currentAppName === 'account-settings',
            testId: 'accountSettings-tab',
            parent: ParentMenu.ADMINISTRATION
        }

        const businessHoursTab = {
            link: `/e${props.userInfo.extension_id}/business-hours`,
            icon: AppsIcon,
            text: appTitles.businessHours,
            routeId: 'business-hours',
            enabled: (userType === 'nxt' && userRole === 'account') && showBusinessHoursTab,
            active: props.currentAppName === 'businessHours',
            testId: 'businessHours-tab',
            parent: ParentMenu.ADMINISTRATION
        }

        const smsDeliveryStatusTab = {
            link: `/e${props.userInfo.extension_id}/sms-delivery-status`,
            icon: AppsIcon,
            text: appTitles.smsDeliveryStatus,
            routeId: 'smsDeliveryStatus',
            enabled: ((userType === 'nxt' || userType === 'classic') && userRole === 'account') && showSMSDeliveryStatusTab,
            active: props.currentAppName === 'sms',
            testId: 'smsDeliveryStatus-tab',
            parent: ParentMenu.REPORT
        }

        const totalTab = {
            link: `/e${props.userInfo.extension_id}/total`,
            icon: AppsIcon,
            text: appTitles.total,
            routeId: 'total',
            enabled: ((userType === 'nxt' || userType === 'classic') && userRole === 'account') && showTotalTab,
            active: props.currentAppName === 'total',
            testId: 'total-tab',
            parent: ParentMenu.REPORT
        }

        const trafficTab = {
            link: `/e${props.userInfo.extension_id}/traffic`,
            icon: AppsIcon,
            text: appTitles.traffic,
            routeId: 'traffic',
            enabled: ((userType === 'nxt' || userType === 'classic') && userRole === 'account') && showTrafficTab,
            active: props.currentAppName === 'traffic',
            testId: 'traffic-tab',
            parent: ParentMenu.REPORT
        }

        const summaryTab = {
            link: `/e${props.userInfo.extension_id}/summary`,
            icon: AppsIcon,
            text: appTitles.summary,
            routeId: 'summary',
            enabled: ((userType === 'nxt' || userType === 'classic') && userRole === 'account') && showSummaryTab,
            active: props.currentAppName === 'summary',
            testId: 'summary-tab',
            parent: ParentMenu.REPORT
        }

        const callTypesTab = {
            link: `/e${props.userInfo.extension_id}/types`,
            icon: AppsIcon,
            text: appTitles.callTypes,
            routeId: 'callTypes',
            enabled: ((userType === 'nxt' || userType === 'classic') && userRole === 'account') && showCallTypesTab,
            active: props.currentAppName === 'types',
            testId: 'call-types-tab',
            parent: ParentMenu.REPORT
        }

        const callLogsTab = {
            link: `/e${props.userInfo.extension_id}/call-logs`,
            icon: AppsIcon,
            text: appTitles.callLog,
            routeId: 'callLogs',
            enabled: (userType === 'nxt' && userRole === 'account') && showCallLogsTab,
            active: props.currentAppName === 'call-logs',
            testId: 'callLogs-tab',
            parent: ParentMenu.REPORT
        }

        const perNumberTab = {
            link: `/e${props.userInfo.extension_id}/per-number`,
            icon: AppsIcon,
            text: appTitles.perNumber,
            routeId: 'per-number',
            enabled: ((userType === 'nxt' || userType === 'classic') && userRole === 'account') && showPerNumberTab,
            active: props.currentAppName === 'per-number',
            testId: 'per-number',
            parent: ParentMenu.REPORT
        }

        const perExtensionTab = {
            link: `/e${props.userInfo.extension_id}/per-extension`,
            icon: AppsIcon,
            text: appTitles.perExtension,
            routeId: 'per-extension',
            enabled: ((userType === 'nxt' || userType === 'classic') && userRole === 'account') && showPerExtensionTab,
            active: props.currentAppName === 'per-extension',
            testId: 'per-extension',
            parent: ParentMenu.REPORT
        }

        const geoDistributionTab = {
            link: `/e${props.userInfo.extension_id}/geo-distribution`,
            icon: AppsIcon,
            text: appTitles.geoDistribution,
            routeId: 'geoDistribution',
            enabled: ((userType === 'nxt' || userType === 'classic') && userRole === 'account') && showGeoDistributionTab,
            active: props.currentAppName === 'geo-distribution',
            testId: 'geo-distribution',
            parent: ParentMenu.REPORT
        }

        const statementsTab = {
            link: `/e${props.userInfo.extension_id}/statements`,
            icon: AppsIcon,
            text: appTitles.statements,
            routeId: 'statements',
            enabled: (userType === 'nxt' && userRole === 'account') && showStatementsTab,
            active: props.currentAppName === 'statements',
            testId: 'statements-tab',
            parent: ParentMenu.BILLING
        }
        const overviewTab = {
            link: `/e${props.userInfo.extension_id}/bill-and-pay`,
            icon: BillingIcon,
            text: appTitles['bill-and-pay'],
            routeId: 'bill-and-pay',
            enabled: userRole === 'account' && showOverviewTab,
            active: props.currentAppName === 'bill-and-pay',
            testId: 'billAndPay-tab'
        }
        const paymentMethodsTab = {
            link: `/e${props.userInfo.extension_id}/payment-methods`,
            icon: AppsIcon,
            text: appTitles.paymentMethods,
            routeId: 'paymentMethods',
            enabled: (userType === 'nxt' && userRole === 'account') && showPaymentMethodsTab,
            active: props.currentAppName === 'payment-methods',
            testId: 'paymentMethods-tab',
            parent: ParentMenu.BILLING
        }

        const routerConfig = refinedNavigationEnabled
            ? [
                callsTab,
                messageTab,
                meetTab,
                voicemailTab,
                faxesTab,
                peopleTab,
                appsMarketPlaceTab,
                appsTab,
                personalSettingsTab,
                settingsTab,
                smsEnrollmentTab,
                configureTab,
                moreServicesTab,

                // Under "Administration" parent tab:
                numbersTab,
                usersTab,
                phonesTab,
                businessHoursTab,
                liveReceptionistTab,
                accountSettingsTab,
                contactInforamtionTab,
                SMSRegistrationTab,
                blockedNumbersTab,

                // Under "Reports" parent tab:
                callLogsTab,
                summaryTab,
                callTypesTab,
                trafficTab,
                geoDistributionTab,
                totalTab,
                perExtensionTab,
                perNumberTab,
                smsDeliveryStatusTab,

                // Under "Billing" parent tab:
                paymentMethodsTab,
                statementsTab,
                overviewTab,
                controlPanelTab,
                helpButton
            ]
            : [
                callsTab,
                messageTab,
                voicemailTab,
                faxesTab,
                peopleTab,
                meetTab,
                appsMarketPlaceTab,
                personalSettingsTab,
                settingsTab,
                smsEnrollmentTab,
                controlPanelTab,
                configureTab,
                moreServicesTab,
                appsTab,
                supportTab,
                logoutTab,
                helpButton,
                numbersTab,
                contactInforamtionTab,
                usersTab,
                phonesTab,
                SMSRegistrationTab,
                blockedNumbersTab,
                liveReceptionistTab,
                accountSettingsTab,
                businessHoursTab,
                smsDeliveryStatusTab,
                totalTab,
                trafficTab,
                summaryTab,
                callLogsTab,
                perNumberTab,
                perExtensionTab,
                geoDistributionTab,
                statementsTab,
                overviewTab,
                paymentMethodsTab
            ]

        if (refinedNavigationEnabled) {
            routerConfig.forEach(tabConfig => {
                tabConfig.text = navItemTitles[tabConfig.routeId]
                if (!navItemTitles[tabConfig.routeId]) {
                    console.error(`No title defined in remote config for communicator nav item: ${tabConfig.routeId}`)
                }
            })
        }

        const tabs = setTabs(routerConfig)
        setRouterConfig(tabs)
        return routerConfig
    }, [props.userInfo, props.unreadCalls, props.unreadFaxes, props.unreadMessages, props.unreadVoicemails, props.unreadPorting, props.currentAppName, props.incompleteE911Count, userRole, videoFeature, ...navItemsDependencyArr])
    const setTabs = (routerConfig) => {
        const tabs = []
        // todo restructure Object structures so we can avoid the messy code below
        routerConfig.forEach(router => {
            if (router.parent) {
                const childIndex = Array.prototype.findIndex.call(router.parent.children, (element) => element.routeId === router.routeId)
                if (childIndex !== -1) { // handles when an object comes in after a state update and not to duplicate it
                    router.parent.children[childIndex] = router
                } else {
                    router.parent.children.push(router)
                }
                // add parent to nav bar items if it is not already added
                const navBarTabs = tabs.map(tab => tab.routeId)
                if (!navBarTabs.includes(router.parent.routeId) && router.enabled) {
                    tabs.push(router.parent)
                }
                delete router.parent
            } else {
                tabs.push(router)
            }
        })
        return tabs
    }
    const setTitle = useCallback(() => {
        const appTitle = appTitles[props.currentAppName] || ''
        const appName = theme.appName || 'My Phone.com'
        window.document.title = `${appTitle} - ${appName}`
    }, [props.currentAppName])

    const getAppBarLabel = useCallback(() => {
        /* eslint-disable-next-line */
        for (const index in routerConfig) {
            const route = routerConfig[index]
            if (window.location.pathname.indexOf(route.link) !== -1) {
                if (appBarLabel !== route.text) setAppBarLabel(route.text)
                return
            }
        }
        if (appBarLabel !== 'Calls') setAppBarLabel('Calls')
    }, [routerConfig, appBarLabel])

    // On mount
    useEffect(() => {
        getTabs()
        getAppBarLabel()
        setTitle()
        getFeatureEnabled('refined_navigation_enabled', setRefinedNavigationEnabled).then(setRefinedNavigationEnabled)
        getFeatureEnabled('more_services_tab_enabled', setShowMoreServicesTab).then(setShowMoreServicesTab)
        getFeatureEnabled('sms_enrollment_enabled', setshowSmsEnrollmenTab).then(setshowSmsEnrollmenTab)
        getFeatureEnabled('apps_marketplace_enabled', setShowAppsMarketplaceTab).then(setShowAppsMarketplaceTab)
        getFeatureEnabled('calls_tab_enabled', setShowCallsTab).then(setShowCallsTab)
        getFeatureEnabled('messages_tab_enabled', setShowMessagesTab).then(setShowMessagesTab)
        getFeatureEnabled('voicemail_tab_enabled', setShowVoicemailTab).then(setShowVoicemailTab)
        getFeatureEnabled('fax_tab_enabled', setShowFaxTab).then(setShowFaxTab)
        getFeatureEnabled('people_tab_enabled', setShowPeopleTab).then(setShowPeopleTab)
        getFeatureEnabled('meetings_tab_enabled', setShowMeetingsTab).then(setShowMeetingsTab)
        getFeatureEnabled('settings_tab_enabled', setShowSettingsTab).then(setShowSettingsTab)
        getFeatureEnabled('configure_tab_enabled', setShowConfigureTab).then(setShowConfigureTab)
        getFeatureEnabled('help_tab_enabled', setShowHelpTab).then(setShowHelpTab)
        getFeatureEnabled('controlPanel_tab_enabled', setShowControlPanelTab).then(setShowControlPanelTab)
        getFeatureEnabled('statements_tab_enabled', setShowStatementsTab).then(setShowStatementsTab)
        getFeatureEnabled('overview_tab_enabled', setShowOverviewTab).then(setShowOverviewTab)
        getFeatureEnabled('payment_methods_tab_enabled', setShowPaymentMethodsTab).then(setShowPaymentMethodsTab)
        getFeatureEnabled('numbers_tab_enabled', setShowNumbersTab).then(setShowNumbersTab)
        getFeatureEnabled('users_tab_enabled', setShowUsersTab).then(setShowUsersTab)
        getFeatureEnabled('phones_tab_enabled', setShowPhonesTab).then(setShowPhonesTab)
        getFeatureEnabled('sms_registration_tab_enabled', setShowSMSRegistrationTab).then(setShowSMSRegistrationTab)
        getFeatureEnabled('blocked_numbers_tab_enabled', setShowBlockedNumbersTab).then(setShowBlockedNumbersTab)
        getFeatureEnabled('live_receptionist_tab_enabled', setShowLiveReceptionistTab).then(setShowLiveReceptionistTab)
        getFeatureEnabled('account_settings_tab_enabled', setShowAccountSettingsTab).then(setShowAccountSettingsTab)
        getFeatureEnabled('business_hours_tab_enabled', setShowBusinessHoursTab).then(setShowBusinessHoursTab)
        getFeatureEnabled('call_logs_tab_enabled', setShowCallLogsTab).then(setShowCallLogsTab)
        getFeatureEnabled('show_sms_delivery_tab_enabled', setShowSMSDeliveryStatusTab).then(setShowSMSDeliveryStatusTab)
        getFeatureEnabled('contact_information_tab_enabled', setShowContactInforamtionTab).then(setShowContactInforamtionTab)
        getFeatureEnabled('total_tab_enabled', setShowTotalTab).then(setShowTotalTab)
        getFeatureEnabled('traffic_tab_enabled', setShowTrafficTab).then(setShowTrafficTab)
        getFeatureEnabled('summary_tab_enabled', setShowSummaryTab).then(setShowSummaryTab)
        getFeatureEnabled('call_logs_tab_enabled', setShowCallLogsTab).then(setShowCallLogsTab)
        getFeatureEnabled('per_number_tab_enabled', setShowPerNumberTab).then(setShowPerNumberTab)
        getFeatureEnabled('per_extension_tab_enabled', setShowPerExtensionTab).then(setShowPerExtensionTab)
        getFeatureEnabled('call_types_tab_enabled', setShowPerExtensionTab).then(setShowCallTypesTab)
        getFeatureEnabled('geo_distribution_tab_enabled', setShowGeoDistributionTab).then(setShowGeoDistributionTab)
        getFeatureEnabled('search').then(f => setSearchFeature(f || !process.env.REACT_APP_IS_SEARCH_DISABLED))
        getFeatureEnabled('video_meeting').then(f => setVideoFeature(f && !process.env.REACT_APP_CONTROL_PANEL_TAB_HIDDEN))
        setMoreServicesTabLink(getValue('more_services_tab_link', (updatedValue) => { setMoreServicesTabLink(updatedValue) }))
        getPhoneCom().then(phoneCom => {
            setUserType(phoneCom.user_tiered ? 'nxt' : 'classic')
            setUserRole(phoneCom.role)
            const configureTabLink = `${(process.env.REACT_APP_CONFIGURE_APP_URL || '')}#access_token=${(phoneCom) ? phoneCom.ac_token : ''}`
            const baseUrl = phoneCom.v5ApiRoot.replace('services', 'app')
            const voipId = phoneCom.voip_id
            const extensionId = phoneCom.voip_phone_id
            const logoutTabLink = `${baseUrl}/communicator/account/logout/?account_id=${voipId}&extension_id=${extensionId}`

            setConfigureTabLink(configureTabLink)
            setLogoutTabLink(logoutTabLink)
        })
    }, [])

    // On component update
    useEffect(() => {
        if (!appNameRef.current || props.currentAppName !== appNameRef.current) {
            getTabs()
            getAppBarLabel()
            setTitle()
            appNameRef.current = props.currentAppName
        }
    }, [props.currentAppName])

    useEffect(() => {
        getTabs()
    }, [props.unreadCalls, props.unreadMessages, props.unreadFaxes, props.unreadVoicemails, props.unreadPorting, props.incompleteE911Count, userRole, videoFeature, ...navItemsDependencyArr])

    const discardChanges = useCallback(() => {
        const extension = discardChangesExtension
        setDiscardChangesExtension(null)
        props.onSwitchExtension(extension)
        props.discardChanges()
    }, [discardChangesExtension, props.onSwitchExtension, props.discardChanges])

    return (
        <>
            <Navigation
                appBarLabel = {appBarLabel}
                mobileOpen = {props.navigationShow}
                tabs = {routerConfig}
                poweredByFooter = {themeContext.showPoweredByFooter}
                appHasChange = {props.appHasChange}
                discardChanges = {props.discardChanges}
                hideAppBar = {true}
                renderNavHeader = {() => (
                    <>
                        <ProfileMenu
                            userInfo = {props.userInfo}
                            currentUser = {props.currentUser}
                            inboxesData = {props.inboxesData}
                            extensionSelectHandler = {extensionSelectHandler}
                            screenViewType = {{ isMobileView: screenSizeContext.mobile, isTabletView: screenSizeContext.tablet }}
                            logout = {props.logout}
                        />
                        {(screenSizeContext.mobile) &&
                            <div style={{ marginTop: 8 }}>
                                {searchFeature &&
                                    <Search
                                        goTo = {props.goTo}
                                        isMobile = {screenSizeContext.mobile}
                                        setMobileDrawerOpen = {() => screenSizeContext.mobile || screenSizeContext.tablet ? props.toggleShowNavigation(!props.navigationShow) : null}
                                        redirect = {props.redirect}
                                    />
                                }
                            </div>
                        }
                    </>
                )}
                onMenuChange = {onMenuChange}
                handleDrawerToggle = {() => screenSizeContext.mobile || screenSizeContext.tablet ? props.toggleShowNavigation(!props.navigationShow) : null}
                refinedNavigationEnabled={refinedNavigationEnabled}
            >{props.children}</Navigation>

            {/* Discard changes modal - should be removed once all apps switch to the LeaveProvider */}
            <ConfirmModal
                title = 'Discard changes?'
                isShown = {Boolean(discardChangesExtension)}
                content = {null}
                noButtonText = 'Cancel'
                yesButtonText = 'Discard'
                yesButtonColor = 'attention'
                onReject = {() => setDiscardChangesExtension(null)}
                onConfirm = {discardChanges}
                size = 'size550'
            />

            <HelpPopup
                onClose = {() => toggleShowHelp(false)}
                display = {showHelp}
                anchorRef = {helpSectionRef}
                extension = {props.extension}
            />
        </>
    )
}

export default CNavigation
