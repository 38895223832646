import React, { useEffect } from 'react'
import { useBillingContext, usePhoenixSessionContext } from 'providers'
// import Cachier from 'cachier'
// const sessionStorageKey = 'user-is-redirected'
import { getPhoneCom } from 'phonecom'

interface PropsInterface {
    onRedirect(v: string): any
}

/**
 *
 */
const RedirectingValidator = ({
    onRedirect
}: PropsInterface): JSX.Element => {
    const { session } = usePhoenixSessionContext()
    const { getData, paymentMethodsPrimary, invoiceDetails } = useBillingContext()

    const billingDataIsValid = (): boolean => {
        if (invoiceDetails && invoiceDetails.cb_is_past_due) {
            return false
        }

        if (paymentMethodsPrimary) {
            const expMonth = paymentMethodsPrimary.details.exp_month
            const expYear = paymentMethodsPrimary.details.exp_year
            const now = new Date()
            const currentYear = now.getFullYear()
            const currentMonth = now.getMonth() + 1
            if (
                currentYear >= expYear ||
                (
                    currentYear === expYear &&
                    currentMonth > expMonth
                )
            ) {
                return false
            }
        }

        return true
    }

    const checkBillingStatus = async () => {
        // const cachier = new Cachier(session.user.id)
        // if (cachier.getItem(sessionStorageKey)) return null

        const { pathname } = window.location
        if (pathname && pathname.includes('/bill-and-pay')) {
            return null
        }

        const phoneCom = await getPhoneCom()
        const isAdmin = phoneCom?.role === 'account'
        if (!isAdmin) return null

        const userBillingDataIsValid = billingDataIsValid()
        if (userBillingDataIsValid === false) {
            onRedirect && onRedirect('/bill-and-pay')
            // cachier.setItem(sessionStorageKey, true)
        }

        return true
    }

    const loadData = async () => {
        if (session && session.user) await getData()
    }

    useEffect(() => {
        loadData()
    }, [session.user])

    useEffect(() => {
        checkBillingStatus()
    }, [invoiceDetails, paymentMethodsPrimary])

    return <></>
}

export default RedirectingValidator
