/**
 *
 */
const style = (t) => {
    const { colors, typography } = t['foundations-library']
    return {
        alert: {
            ...typography.subtitle2,
            borderRadius: '6px',
            padding: '8px 30px 8px 40px',
            display: 'flex',
            minHeight: '50px',
            '&.alert--no-border-radius': {
                borderRadius: '0 !important'
            },
            '&.alert--error': {
                color: 'white',
                background: colors.destructive[400]
            },
            '&.alert--success': {
                color: colors.primary[500],
                background: colors.primary[50]
            },
            '& .alert--message': {
                display: 'inline-block',
                verticalAlign: 'middle'
            },
            '& .alert--icon': {
                marginRight: '4px'
            },
            '& .alert--close-icon': {
                marginLeft: 'auto',
                cursor: 'pointer'
            },
            '& .alert--button': {
                marginLeft: '10px'
            }
        }
    }
}
export default style
