import ajax from 'ajax'
import PhoneComUser from 'phone-com-user'
import { getPhoneCom } from 'phonecom'

const numberManagerBase = process.env.REACT_APP_NUMBER_MANAGER_API_URL
const namespaces = ['porting']
class API {
    static loadPhoneNumbers = async (cursor = null, filters = {}, limit = 20, sort = 'asc') => {
        const data = { filters, sort, limit }
        if (cursor) data.cursor = cursor
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/phone-numbers/list-phone-numbers-2-2`
        const response = await ajax.post(requestUrl, data)
        return response.data
    }

    static getTransfers = async (voipId) => {
        const requestUrl = `${process.env.REACT_APP_NUMBER_MANAGER_API_URL}/voip/${voipId}/port-requests`
        const response = await ajax.get(requestUrl)
        return response.data
    }

    static getMainCompanyNumberVoipDidId = async () => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/phone-numbers/get-main-company-number`
        const response = await ajax.post(requestUrl, {})
        return response.data
    }

    static configureCalling = async (numberType, data) => {
        data.number_type = numberType
        const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/user/configure-calling`
        const response = await ajax.post(requestUrl, data)
        return response.data
    }

    static createFileGreeting = async (name, file, generate_call_menu_audio = false) => {
        await getPhoneCom()
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/media/create-greeting`
        const data = { name, file, generate_call_menu_audio, prepend_silence: '1' }
        if (!generate_call_menu_audio) {
            data.prepend_silence = '2'
            data.append_silence = '1'
        }
        let fileType = name.split('.')
        fileType = fileType[fileType.length - 1]
        switch (fileType) {
                case 'mp3':
                    data.content_type = 'audio/mpeg'
                    break
                case 'wav':
                    data.content_type = 'audio/wav'
                    break
                case 'ulaw':
                    data.content_type = 'audio/basic'
                    break
        }
        return ajax.post(requestUrl, data).then(response => response.data)
    }

    static createTTSGreeting = async (tts_text, tts_voice, name, generate_call_menu_audio = false) => {
        await getPhoneCom()
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/media/create-greeting`
        const data = { origin: 'tts', tts_text, tts_voice, name, generate_call_menu_audio, prepend_silence: '1' }
        if (!generate_call_menu_audio) {
            data.prepend_silence = '2'
            data.append_silence = '1'
        }
        return ajax.post(requestUrl, data).then(response => response.data)
    }

    static updatePhoneNumber = async (payload) => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/phone-numbers/update-phone-number`
        return await ajax.post(requestUrl, payload)
    }

    static toggleCallRecording = async data => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/phone-numbers/toggle-call-recording`
        return await ajax.post(requestUrl, data)
    }

    static loadUsers = async (page_size, offset) => {
        const account_id = PhoneComUser.getAPIAccountId()
        const api_base = process.env.REACT_APP_USER_API_URL
        const requestUrl = `${api_base}/voip/${account_id}/users?page_size=${page_size}&offset=${offset}&order_by=status.asc&include=extension,plan,add_ons,devices,numbers`
        const response = await ajax.get(requestUrl)
        if (response.message === 'Network Error' || !response.data) return []
        // TODO: temporarily update reponse.data.data to change user_plan_id from 4,5,6 to 1,2,3 respectively
        response.data.data.forEach((user) => {
            if ([4, 5, 6].includes(user.user_plan_id)) {
                user.user_plan_id = user.user_plan_id - 3
            }
        })
        return response.data.data
    }

    static unassignNumber = async (voip_did_id, voip_phone_ids) => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/user/configure-calling`
        const response = await ajax.post(requestUrl, { unassign: true, voip_did_id, voip_phone_ids })
        if (response.response && response.response.status >= 400) {
            // Response is an axios error object - server returned a non-2xx response,
            // but we can return response payload
            console.log('Got non-2xx response from server:', response.response.data)
            return response.response.data
        }
        try {
            return response.data ? response.data : { error: response.errors }
        } catch (err) {
            console.error('Error calling configure-calling endpoint', err)
            return { error: true }
        }
    }

    static loadSchedules = async () => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/schedules/list-schedules`
        const response = await ajax.post(requestUrl, {})
        return response.data
    }

    static getCompanySettings = () => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/accounts/get-account-settings`
        return ajax.post(requestUrl, {}).then(response => response.data)
    }

    static setBadgeValue = async (voipId, namespace, value, expiration = null) => {
        const url = `${numberManagerBase}/voip/${voipId}/set-badge-value`

        const headers = {
            'Content-Type': 'application/json',
            Authorization: `CP ${window.V5PHONECOM.cp_token}`
        }

        const body = {
            namespace: namespace,
            value: value,
            expiration: expiration
        }

        return fetch(url, {
            method: 'POST',
            body: JSON.stringify(body),
            headers
        })
            .then(res => res.json())
            .catch(console.warn)
    }

    static getBadgeValues = async (voipId) => {
        const url = `${numberManagerBase}/voip/${voipId}/get-badge-value?`

        const headers = {
            'Content-Type': 'application/json',
            Authorization: `CP ${window.V5PHONECOM.cp_token}`
        }

        const badgeReadValues = {}

        await Promise.all(
            namespaces.map(async (namespace) => {
                const response = await fetch(url + new URLSearchParams({
                    namespace: namespace
                }), {
                    method: 'GET',
                    headers
                })
                const badgeReadValue = await response.json()
                badgeReadValues[namespace] = badgeReadValue
            })
        )

        return badgeReadValues
    }

    static deleteNumber = async (voip_did_id) => {
        const requestUrl = `${process.env.REACT_APP_NUMBER_MANAGER_API_URL}/delete-number`
        const response = await ajax.post(requestUrl, { voip_did_id: voip_did_id })
        return response.data.data
    }

    /**
     * @param { Int | String } voipDid  - the voip did
     * @param { String } callerName  - the caller name
     */
    static async updateCallerName (voipDid, callerName) {
        const requestUrl = `${PhoneComUser.getv5ToolsRoot()}/number-management/update-number-caller-name`
        const data = { voip_did: voipDid, caller_name: callerName }
        const response = await ajax.post(requestUrl, data)
        return response.data
    }

    static uploadFile = async (voipId, file, key) => {
        const requestUrl = `${process.env.REACT_APP_NUMBER_MANAGER_API_URL}/voip/${voipId}/upload-porting-file`
        const data = { image: file, key: key }
        const response = await ajax.post(requestUrl, data)
        if (response.response && response.response.status >= 400) {
            // Response is an axios error object - server returned a non-2xx response,
            // but we can return response payload
            console.log('Got non-2xx response from server:', response.response.data)
            return response.response.data
        }
        try {
            return response.data ? response.data : { error: true }
        } catch (err) {
            console.error('Error calling upload-porting-file endpoint', err)
            return { error: true }
        }
    }

    static createPortRequest = async (voipId, payload) => {
        const requestUrl = `${process.env.REACT_APP_NUMBER_MANAGER_API_URL}/voip/${voipId}/port-request`
        const response = await ajax.post(requestUrl, payload)
        if (response.response && response.response.status >= 400) {
            // Response is an axios error object - server returned a non-2xx response,
            // but we can return response payload
            console.log('Got non-2xx response from server:', response.response.data)
            return response.response.data
        }
        try {
            return response.data ? response.data : { error: true }
        } catch (err) {
            console.error('Error calling port-request endpoint', err)
            return { error: true }
        }
    }

    static validateNumberAssignmentList = async (voipId, payload) => {
        // Payload structure:
        // { "assignment_list": [
        //         {
        //             "number_to_transfer": "+14125551212",
        //             "temporary_number": "+14125553434"
        //         }
        // ] }
        const requestUrl = `${process.env.REACT_APP_NUMBER_MANAGER_API_URL}/voip/${voipId}/port-request/validate/number_assignment_list`
        const response = await ajax.post(requestUrl, payload)
        if (response.response && response.response.status >= 400) {
            // Response is an axios error object - server returned a non-2xx response,
            // but we can return response payload
            console.log('Got non-2xx response from server:', response.response.data)
            return response.response.data
        }
        try {
            return response.data ? response.data : { error: true }
        } catch (err) {
            console.error('Error calling number_assignment_list endpoint', err)
            return { error: true }
        }
    }

    static validateNumberInfoStep = async (payload) => {
        const voipId = PhoneComUser.getAPIAccountId()
        const requestUrl = `${process.env.REACT_APP_NUMBER_MANAGER_API_URL}/voip/${voipId}/port-request/validate/number_info`
        const response = await ajax.post(requestUrl, payload)
        if (response.response && response.response.status >= 400) {
            // Response is an axios error object - server returned a non-2xx response,
            // but we can return response payload
            console.log('Got non-2xx response from server:', response.response.data)
            return response.response.data
        }
        try {
            return response.data ? response.data : { error: true }
        } catch (err) {
            console.error('Error calling validate/number_info endpoint', err)
            return { error: true }
        }
    }
}

export default API
