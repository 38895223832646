import React, { useEffect } from 'react'
import style from './style'
import Icon from '../Icon/Icon'
import Button, { ButtonType } from '../Button/Button'
import IconName from '../../helpers/types/IconName.interface'
import { makeStyles } from '@material-ui/core'
import { PropsWithChildren } from 'react'

const useStyles = makeStyles(style)

type AlertLevelPropType = 'success' | 'error'
interface PropsInterface {
    level?: AlertLevelPropType;
    onClose?(): any;
    buttonProps?: ButtonType;
    icon?: boolean | IconName;
    noBorderRadius?: boolean;
    autocloseAfter?: false | number;
    'data-testid'?: string;
    marginY?: number; // px
    autoclose?: boolean; // autcloses after 5000ms if autocloseAfter not provided
}

const icons = {
    error: {
        name: 'alert',
        color: 'white'
    },
    success: {
        name: 'copy',
        color: 'primary'
    }
}

const renderIcon = (icon, level) => {
    if (icon === false) return ''
    let iconName
    if (icon === true) {
        iconName = icons[level] ? icons[level].name : 'alert'
    } else {
        iconName = icon
    }

    return <Icon name={iconName} color={icons[level] ? icons[level].color : 'white'} className='alert--icon'/>
}

const renderButton = (buttonProps, level) => {
    if (!buttonProps) return ''

    return <Button {...buttonProps} variant='outlined' color={icons[level] ? icons[level].color : 'white'} className='alert--button' size='small'/>
}

const renderCloseButton = (cb, level) => {
    if (!cb || (cb && typeof cb !== 'function')) {
        return ''
    }

    return <Icon name='close' color={icons[level] ? icons[level].color : 'white'} className='alert--close-icon' onClick={cb} data-testid='close-alert-btn'/>
}

/**
 *
 */
const Alert = ({
    level = 'error',
    onClose,
    buttonProps,
    icon = true,
    children,
    noBorderRadius = false,
    autocloseAfter = false,
    marginY,
    autoclose = false,
    'data-testid': dataTestId = 'alert'
}: PropsWithChildren<PropsInterface>): JSX.Element => {
    if (!children) return <></>

    const styles = useStyles()
    useEffect(() => {
        if (onClose && autocloseAfter) {
            setTimeout(() => {
                onClose()
            }, autocloseAfter)
        } else if (onClose && autoclose) {
            setTimeout(() => {
                onClose()
            }, 5000)
        }
    }, [autocloseAfter, onClose])
    const classes = [level, noBorderRadius && 'no-border-radius'].filter((x) => x).map((x) => `alert--${x}`).join(' ')
    let inlineStyle
    if (marginY) {
        const pxs = `${marginY}px`
        inlineStyle = { marginTop: pxs, marginBottom: pxs }
    }
    return (
        <div className={`${styles.alert} ${classes}`} style={inlineStyle} data-testid={dataTestId}>
            {renderIcon(icon, level)}
            <span className='alert--message'>
                {children}
            </span>
            {renderButton(buttonProps, level)}
            {renderCloseButton(onClose, level)}
        </div>
    )
}

/**
 *
 */
export type AlertType = PropsWithChildren<PropsInterface>
/**
 *
 */
export type AlertLevelType = AlertLevelPropType

export default Alert
