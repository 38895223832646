import { useState } from 'react'
import { AlertType, AlertLevelType } from 'foundations-library/types'
import { getValidationError } from 'errors'
import { ErrorType } from 'interfaces/v4'

type ReturnedArrayType = [
    Partial<AlertType> | false,
    (text: string, level?: AlertLevelType) => AlertType,
    Record<string, () => any>
]

const genericError = 'Something went wrong'

/**
 * preset of alert props that will be used in big number of cases
 * Usage:
 * const [alert, setAlert, genericAlerts] = useAlert({autoclose: false, onClose: () => scrollToTop()})
 * { alert && <Alert {...alert} />}
 * setAlert('Message', 'error') / setAlert(null) / setAlert(err, 'error') -> v4 error
 * genericAlerts.successfulCreation()
 */
export const useAlert = (props: AlertType = {}): ReturnedArrayType => {
    const defaultProps = {
        level: 'success' as AlertLevelType,
        onClose: () => setAlertTextAndLevel(null),
        marginY: 20,
        autoclose: true
    }
    const [alertComponentProps, setAlertComponentProps] = useState<Partial<AlertType>>({
        ...defaultProps,
        ...props
    })

    const setAlertTextAndLevel = (
        data: null | string | ErrorType,
        level: AlertLevelType = 'success'
    ): any => {
        let children
        if (data && typeof data === 'object' && level === 'error') {
            const errorMessage = getValidationError(data)
            children = errorMessage || genericError
        } else if (!data && level === 'error') {
            children = genericError
        } else {
            children = data || ''
        }
        let value
        if (children) {
            value = {
                ...defaultProps,
                ...alertComponentProps,
                children,
                level
            }
        } else {
            value = null
        }
        return setAlertComponentProps(value)
    }

    const generic = {
        success: () => setAlertTextAndLevel('Success.'),
        successfulCreation: () => setAlertTextAndLevel('Successfully created.'),
        successfulDeletion: () => setAlertTextAndLevel('Successfully deleted.'),
        successfulUpdate: () => setAlertTextAndLevel('Changes saved.'),
        error: () => setAlertTextAndLevel(genericError, 'error')
    }

    return [
        alertComponentProps,
        setAlertTextAndLevel,
        generic
    ]
}

export default useAlert
