/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import ajax from 'ajax'
import PhoneComUser from 'phone-com-user'

/**
 *
 */
type SubmitPaymentPayloadType = {
    amount: string;
    payment_info_id: number;
}

/**
 *
 */
const submitPayment = async (data: SubmitPaymentPayloadType) => {
    const payload = { ...data }
    payload.account_id = PhoneComUser.getAPIAccountId()
    const requestUrl = `${PhoneComUser.getv5ApiRoot()}/billing/process-payment`
    const response = await ajax.postClean(requestUrl, payload)
    return response
}

/**
 *
 */
export {
    submitPayment
}
