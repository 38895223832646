import React, { useState, useEffect } from 'react'
import Selector, { SelectorType } from './Selector'
import { Dialog, FormButtons } from 'functional-foundations'
import { DialogType } from 'functional-foundations/types'

interface PropsInterface extends DialogType, SelectorType {
}

/**
 *
 */
const DialogSelector = ({
    value,
    onChange,
    onConfirm,
    disabled,
    'data-testid': dataTestId = 'payment-methods-selector',
    ...props
}: PropsInterface): JSX.Element => {
    const [selected, setSelected] = useState(value)
    const [activeTab, setActiveTab] = useState('list')
    const [initialValue, setInitialValue] = useState(null)

    useEffect(() => setInitialValue(value), [])
    useEffect(() => onChange && onChange(selected), [selected])
    const handleOnChange = (val) => {
        setSelected(val)
    }

    const closeDialog = () => props.onClose && props.onClose()

    const handleOnConfirm = () => {
        onChange && onChange(selected)
        onConfirm && onConfirm(selected)
        closeDialog()
    }

    const afterCreateCb = (obj) => {
        handleOnChange(obj.id)
        setTimeout(closeDialog, 3000)
    }

    const handleOnCancel = () => {
        setSelected(initialValue)
        if (props.onClose) props.onClose()
    }

    return <Dialog
        {...props}
        title='Payment method'
        data-testid={`${dataTestId}-dialog`}
    >
        <Selector
            value={selected}
            onChange={handleOnChange}
            disabled={disabled}
            activeTab={activeTab}
            onTabChange={setActiveTab}
            afterCreateCb={afterCreateCb}
            onCancel={handleOnCancel}
            data-testid={dataTestId}
        />
        {activeTab === 'list' && <FormButtons confirmText={'Continue'} onCancel={handleOnCancel} onConfirm={handleOnConfirm} disableConfirm={!selected}/>}
    </Dialog>
}

/**
 *
 */
export type DialogSelectorPropsType = PropsInterface

export default DialogSelector
