import React from 'react'
import { useValidation } from 'hooks'
import { makeStyles } from '@material-ui/core/styles'
import style from './style'
import Icon from '../Icon/Icon'
import Typography from '../Typography/Typography'
import IconButton from '../IconButton/IconButton'
import CommonInputType from '../../helpers/types/CommonInput.interface'

const useStyles = makeStyles(style)

const renderHelperText = (hText, stls) => {
    if (!hText) return ''
    if (typeof hText === 'string') {
        return (
            <div className={stls.inputHelperText} data-testid='helper-text'>
                <Typography tag='span' variant='helperText' color='neutral-400' className='helper-text'>
                    <Icon color='neutral-400' name='notification-alert' size={16} className='helper-text-icon'/> { hText }
                </Typography>
            </div>
        )
    }

    return { hText }
}

interface PropsInterface extends CommonInputType {
    children: string | JSX.Element | JSX.Element[];
    label?: string | false
}

const renderValidationErrors = (errs, stls) => {
    if (errs && errs.length) {
        return (
            <Typography variant='helperText' color='destructive-500' data-testid='validation-errors'>
                {errs.map((x, i) => <div className={ stls.inputValidationError } key={`validation-error-${i}`}>{x}</div>)}
            </Typography>
        )
    }
    return ''
}

/**
 *
 */
const CommonInput = ({
    value,
    rules,
    isValid,
    children,
    helperText,
    onClear,
    style,
    disabled,
    clearable = false,
    size,
    fullWidth = false,
    className,
    label = false,
    leadingIcon,
    trailingIcon,
    onClickLeadingIcon,
    onClickTrailingIcon,
    'data-testid': dataTestID = ''
}: PropsInterface): JSX.Element => {
    const styles = useStyles()
    const [errors] = useValidation(value, rules, isValid)
    const dataTestId = `${dataTestID}-common-input`
    const wrapperClasses = [
        'input-wrapper',
        errors && errors.length ? 'has-error' : '',
        leadingIcon && 'has-leading-icon',
        trailingIcon && 'has-trailing-icon',
        size || ''
    ].filter((x) => x).join(' ')

    const userMayClearTheField = value && clearable && onClear

    return (
        <div className={`${styles.commonInput} ${fullWidth && 'common-input--full-width'} ${userMayClearTheField && 'common-input-clearable'} ${className || ''}`} data-testid={dataTestId}>
            <div className={wrapperClasses} style={style}>
                {label && <label className='label-in-border'>{label}</label>}
                {
                    leadingIcon &&
                    <Icon
                        onClick={onClickLeadingIcon}
                        color={disabled ? 'neutral-300' : 'neutral-600'}
                        name={leadingIcon}
                        size={20}
                        className='leading-icon'
                        data-testid={`${dataTestID}-leading-icon`}
                    />
                }
                {children}
                {userMayClearTheField && <IconButton
                    className='clear-input-button'
                    size='small'
                    variant='icon'
                    color='neutral'
                    name='close'
                    onClick={onClear}
                    data-testid='clear-field-button'
                />}
                {
                    trailingIcon &&
                    <Icon
                        name={trailingIcon}
                        onClick={onClickTrailingIcon}
                        color={disabled ? 'neutral-300' : 'neutral-600'}
                        size={20}
                        className='trailing-icon'
                        data-testid={`${dataTestID}-trailing-icon`}
                    />
                }
            </div>
            { renderValidationErrors(errors, styles) }
            { renderHelperText(helperText, styles) }
        </div>
    )
}

export default CommonInput
