/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useMemo, useState } from 'react'
import { Icon, Table, Typography, Button, Alert } from 'foundations-library/components'
import { makeStyles } from '@material-ui/core'
import style from './style'
import { formatPrice } from 'formatters'
import Actions from './components/Actions'
import ListLoader from './components/ListLoader'
import { generateResultAlertDownloadAllPdf } from './helpers/list'
import JSZip from 'jszip'
import { downloadFile } from 'helpers'
import { useStatementsContext } from 'providers'
import { useAlert } from 'hooks'

const useStyles = makeStyles(style)
const linkAccessor = '_linkComponent'

type dataType = Record<string, unknown>[] | undefined

const sortingCb = (field, order, data): dataType => {
    if (field) {
        let sortingField
        if (field === 'formattedDate') {
            sortingField = 'timestamp'
        } else if (field === 'formattedPrice') {
            sortingField = 'total_charges_in_cents'
        }
        if (sortingField) {
            const sorted = data.sort((a, b) => order === 'asc' ? a[sortingField] - b[sortingField] : b[sortingField] - a[sortingField])
            return sorted
        }
    }
}

/**
 *
 */
const Statements = () => {
    const styles = useStyles()

    const [loading, setLoading] = useState(false)
    const [alert, setAlert, genericAlerts] = useAlert()
    const [loadingPdfIds, setLoadingPdfIds]: number = useState([])
    const [loadingPdfs, setLoadingPdfs] = useState(false)

    const {
        getPdfBlob,
        items,
        getData,
        getInvoicePdfLink,
        statementsTimeFormat,
        getFileName,
        downloadPdf
    } = useStatementsContext()

    const getInvoices = async () => {
        if (items) return items
        setLoading(true)
        try {
            await getData()
        } catch (err) {
            setAlert(err, 'error')
        }
        setLoading(false)
    }

    useEffect(() => {
        getInvoices()
    }, [])

    const openPdf = async (invoiceId) => {
        // Create a blank popup immediately on user action to avoid browser popup blockers
        setLoadingPdfIds([...loadingPdfIds, invoiceId])
        const invoiceTab = window.open('', '_blank')
        if (invoiceTab) invoiceTab.document.write('Loading invoice - please wait...')
        const res = await getInvoicePdfLink(invoiceId)
        if (invoiceTab && res && res.invoice_link) {
            invoiceTab.location.href = res.invoice_link
        } else if (invoiceTab) {
            invoiceTab.document.write('Could not load invoice')
        }
        setLoadingPdfIds(loadingPdfIds.filter((x) => x === invoiceId))
    }

    const downloadSinglePdf = async (invoiceId, supressAlert = false) => {
        if (!items) return null

        setLoadingPdfIds([...loadingPdfIds, invoiceId])
        let result

        try {
            const filename = await downloadPdf(invoiceId)
            result = { level: 'success', message: `${filename} downloaded`, filename }
        } catch (err) {
            console.log(err)
            result = { level: 'error', message: 'Something went wrong', filename: `#${invoiceId}` }
        }
        if (!supressAlert) setAlert(result.message, result.level)
        setLoadingPdfIds(loadingPdfIds.filter((x) => x === invoiceId))
        return result
    }

    const downloadAll = async () => {
        if (!items) return items

        const zip = new JSZip()

        setLoadingPdfs(true)
        const blobs = await Promise.all(items.map(
            async (statement) => {
                let result
                try {
                    result = await getPdfBlob(statement.statement_id)
                } catch (err) {
                    console.log(err)
                }

                return result
            }
        ))

        const results = []
        for (let i = 0; i < items.length; i++) {
            const statement = items[i]
            setLoadingPdfIds([...loadingPdfIds, statement.statement_id])
            try {
                const fileName = getFileName(statement.statement_id)
                if (blobs[i]) {
                    zip.file(fileName, blobs[i])
                    results.push({ level: 'success', message: `${fileName} downloaded`, filename: fileName })
                } else {
                    throw new Error(`Pdf not available for #${statement.statement_id}`)
                }
            } catch (err) {
                console.log(err)
                results.push({ level: 'error', message: 'Something went wrong' })
            }
            setLoadingPdfIds(loadingPdfIds.filter((x) => x === statement.statement_id))
        }
        try {
            const blob = await zip.generateAsync({ type: 'blob' })
            downloadFile(blob, 'statements.zip')
            const finalAlert = generateResultAlertDownloadAllPdf(results)
            setAlert(finalAlert.message, finalAlert.level)
        } catch (err) {
            genericAlerts.error()
        }
        setLoadingPdfs(false)
    }

    const tableData = useMemo(() => {
        if (!items) return null
        const formattedData = items.map((x) => {
            const date = statementsTimeFormat(x.billing_period)
            x[linkAccessor] = <span className={styles.getFileFromListButton} onClick={() => openPdf(x.statement_id)}>
                <Icon name='billing' size={20} color='primary-500' className={styles.billingIcon} />
                <Typography tag='span' variant="subtitle3" color="primary">{date}</Typography>
            </span>
            x.formattedDate = date
            x.formattedPrice = formatPrice(x.total_charges_in_cents, '$')
            const loading = loadingPdfIds.includes(x.statement_id) || loadingPdfs
            x.actions = <Actions
                loading={loading}
                data-testid={`actions-button-${x.statement_id}`}
                item={x} options={[
                    { text: 'View', value: 'view', cb: openPdf },
                    { text: 'Download', value: 'download', cb: downloadSinglePdf }
                ]}
            />
            return x
        })
        return formattedData
    }, [items, loadingPdfIds, loadingPdfs])

    return <div className={styles.statements} data-testid='statements'>
        {loading && <ListLoader />}
        {!loading && items && items.length && tableData && <div data-testid='statements-content'>
            {alert && <Alert { ...alert } data-testid='statements-alert'/>}
            <div
                className={styles.downloadAllButtonWrapper}
            >
                <Button
                    onClick={downloadAll}
                    icon='download'
                    iconLeft
                    disabled={loadingPdfs}
                    data-testid='statements-download-button'
                >Download all</Button>
            </div>
            <Table columns={[
                { label: 'Invoice', accessor: linkAccessor, width: '75%' },
                { label: 'Billing date', accessor: 'formattedDate', sortable: true },
                { label: 'Payments', accessor: 'formattedPrice', sortable: true },
                { label: '', accessor: 'actions' }
            ]}
            data={tableData}
            sortingCb={sortingCb}
            data-testid='statements-table'
            />
        </div>}
    </div>
}

export default Statements
