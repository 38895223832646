const boxShadow = (color, borderSize = '1px') => ({ boxSizing: 'border-box', boxShadow: `inset 0 0 1px ${borderSize} ${color}` })
const inputSelectors = '& input, & select, & .select'

/**
 *
 */
const styles = (t) => {
    const { colors, typography } = t['foundations-library']
    return {
        commonInput: {
            '&.common-input--full-width': {
                width: '100%',
                '& .input-wrapper': { width: '100%' },
                [inputSelectors]: { width: '100%' }
            },
            '&.common-input-clearable': {
                '& input': {
                    paddingRight: '40px'
                }
            },
            '& .input-wrapper': {
                width: '100%',
                borderBottom: 0,
                position: 'relative',
                display: 'inline-flex',
                alignItems: 'center',
                '& .label-in-border': {
                    position: 'absolute',
                    top: '-6px',
                    left: '14px',
                    backgroundColor: 'white',
                    paddingLeft: '4px',
                    paddingRight: '4px',
                    ...typography.label,
                    color: colors.neutral[500]
                },
                '&.large': {
                    height: '44px',
                    '& label': {
                        left: '14px'
                    }
                },
                '&.small': {
                    height: '38px',
                    '& label': {
                        left: '10px'
                    }
                },
                '& .clear-input-button': {
                    display: 'none',
                    position: 'absolute',
                    right: '6px'
                },
                '&:hover': {
                    '& .clear-input-button': {
                        display: 'inline'
                    }
                },
                '& .leading-icon': {
                    position: 'absolute',
                    left: '14px'
                },
                '& .trailing-icon': {
                    position: 'absolute',
                    right: '14px'
                },
                '&.has-leading-icon': {
                    [inputSelectors]: {
                        // paddingRight: '20px' // paddingRight doesnt work
                        padding: '8px 10px 8px 40px'
                    }
                },
                '&.has-traling-icon': {
                    [inputSelectors]: {
                        // paddingRight: '20px' // paddingRight doesnt work
                        padding: '8px 40px 8px 14px'
                    }
                },
                '&.has-traling-icon.has-leading-icon': {
                    [inputSelectors]: {
                        // paddingRight: '20px' // paddingRight doesnt work
                        padding: '8px 40px 8px 40px'
                    }
                }
            },
            [inputSelectors]: {
                borderRadius: '6px',
                padding: '8px 10px 8px 14px',
                height: '100%',
                width: '100%',
                ...boxShadow(colors.neutral[400]),
                ...typography.body2,
                color: colors.neutral[900],
                transition: 'box-shadow 0.2s',
                '&::placeholder': {
                    ...typography.body2,
                    color: colors.neutral[700]
                },
                '&:hover': {
                    ...boxShadow(colors.neutral[400], '2px')
                },
                '&:focus, &.input--focused': {
                    ...boxShadow(colors.neutral[700], '2px'),
                    outline: 'none'
                },
                '&:disabled': {
                    ...boxShadow(colors.neutral[400]),
                    color: colors.neutral[300],
                    '&::placeholder': {
                        color: colors.neutral[300]
                    }
                }
            },
            '& .has-error': {
                [inputSelectors]: {
                    ...boxShadow(colors.destructive[500]),
                    '&:focus': {
                        ...boxShadow(colors.destructive[700], '2px')
                    },
                    '&:hover': {
                        ...boxShadow(colors.destructive[500], '2px')
                    }
                }
            }
        },
        inputValidationError: {
            marginTop: '7px'
        },
        inputHelperText: {
            ...typography.helperText,
            lineHeight: '16px',
            marginTop: '8px',
            '& .helper-text .helper-text-icon': {
                marginRight: '5px'
            }
        }
    }
}

export default styles
