/**
 *
 */
const style = () => ({
    statements: {
        maxWidth: '900px',
        margin: '40px auto 0 auto'
    },
    billingIcon: {
        marginRight: '8px'
    },
    downloadAllButtonWrapper: {
        marginBottom: '20px',
        textAlign: 'right'
    },
    getFileFromListButton: {
        cursor: 'pointer'
    }
})

export default style
