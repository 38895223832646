// Modal as a separate component
import React, { useEffect, useRef, PropsWithChildren, useCallback } from 'react'
import { makeStyles } from '@material-ui/core'
import { IconButton, Typography } from 'foundations-library/components'

const jss = () => ({
    dialog: {
        top: '50%',
        left: '50%',
        border: 'none',
        borderRadius: '20px',
        background: 'white',
        '-webkit-transform': 'translateX(-50%) translateY(-50%)',
        '-moz-transform': 'translateX(-50%) translateY(-50%)',
        '-ms-transform': 'translateX(-50%) translateY(-50%)',
        transform: 'translateX(-50%) translateY(-50%)',
        '& .dialog-container': {
            padding: '55px 40px 40px 40px'
        },
        '&::backdrop': {
            backgroundColor: 'rgba(0, 0, 0, 0.6)'
        },
        '& .close-dialog-x': {
            position: 'absolute',
            top: '20px',
            right: '25px'
        },
        '& .dialog-title': {
            marginBottom: '20px'
        }
    }
})

const sizeWidth = {
    small: 400,
    medium: 600,
    large: 900
}

const useStyles = makeStyles(jss)

interface PropsInterface {
    isOpen: boolean
    onClose?(): any
    onClick?(e): any
    className?: string
    title?: string
    closeOnClickOutside?: boolean,
    width?: number // px
    size?: 'small' | 'medium' | 'large'
    style?: Record<string, unknown>
    'data-testid'?: string;
}

/**
 *
 */
const Dialog = ({
    isOpen = false,
    onClose,
    onClick,
    children,
    className,
    title,
    width,
    style = {},
    size = 'medium',
    closeOnClickOutside = true,
    'data-testid': dataTestId = 'dialog'
}: PropsWithChildren<PropsInterface>): JSX.Element => {
    const styles = useStyles()
    const ref = useRef<HTMLDialogElement | null>(null)

    useEffect(() => {
        if (isOpen) {
            ref.current?.showModal()
        } else {
            ref.current?.close()
        }
    }, [isOpen])

    const elStyle = {
        ...style,
        width: width || sizeWidth[size]
    }

    const closeDialog = () => onClose && onClose()
    const handleOnClick = useCallback((e) => {
        if (closeOnClickOutside &&
            ref.current &&
            ref.current === e.target
        ) {
            closeDialog()
        }
        if (onClick) onClick(e)
    }, [closeOnClickOutside])
    return (
        <dialog
            ref={ref}
            onCancel={closeDialog}
            className={`${styles.dialog} ${className || ''}`}
            style={elStyle}
            onClick={handleOnClick}
            data-testid={dataTestId}
        >
            <div className="dialog-container" data-testid={`${dataTestId}-container`}>
                {title && <Typography variant='h6' className='dialog-title' data-testid={`${dataTestId}-title`}>{title}</Typography>}
                <div className="dialog-content" data-testid={`${dataTestId}-content`}>
                    {children}
                </div>
                {
                    onClose &&
                        <IconButton
                            name='close'
                            variant='icon'
                            color='neutral'
                            onClick={closeDialog}
                            className='close-dialog-x'
                            data-testid={`${dataTestId}-close-button`}
                        />
                }
            </div>
        </dialog>
    )
}

/**
 *
 */
export type DialogType = PropsWithChildren<PropsInterface>

export default Dialog
