import React, { useEffect, useRef, useState } from 'react'
import { Divider, Skeleton } from 'foundations-library/components'
import { makeStyles } from '@material-ui/core'
const style = () => ({
    container: {
        padding: '0 42px',
        marginTop: '30px'
    },
    tabs: {
        height: '32px',
        margin: '20px 0 22px 0',
        display: 'flex',
        alignItems: 'center'
    }
})

const useStyles = makeStyles(style)

interface PropsInterface {
    fillContent?: boolean
}

/**
 *
 */
const PMLoader = ({
    fillContent = false
}: PropsInterface): JSX.Element => {
    const styles = useStyles()
    const [times, setTimes] = useState(fillContent ? 0 : 1)
    const itemsRef = useRef(null)
    useEffect(() => {
        if (itemsRef?.current && !itemsRef?.current?.closest('[data-testid="dialog"]')) {
            try {
                setTimes(Math.abs(Math.floor(
                    (window.innerHeight - itemsRef.current.offsetTop) / (78 + 15)
                ) - 1))
            } catch (err) {
                console.log('Error calculating height', err)
                setTimes(1)
            }
        }
    }, [])

    return <div className={styles.container} data-testid='payment-methods-loader'>
        <div className={styles.tabs}>
            <Skeleton variant='rect' height={16} width={77} data-testid='pm-loader-list' />
            <Divider color='neutral-200' height={19} orientation='vertical' />
            <Skeleton variant='rect' height={16} width={93} data-testid='pm-loader-create' />
        </div>
        <div ref={itemsRef}>
            {times && ([...Array(times)].map((x, i) => i + 1))
                .map(
                    (x) => (
                        <Skeleton
                            variant='rect'
                            height={78}
                            width='100%'
                            style={{ marginTop: '15px' }}
                            key={`pm-loader-${x}`}
                            data-testid={`pm-loader-item-${x}`}
                        />
                    )
                )
            }
        </div>
    </div>
}

export default PMLoader
