import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import PaymentMethodsIndex from './screens/List/List'
import PaymentMethodsCreate from './screens/Create/Create'

interface PropsInterface {
    routeProps: any;
    smallView: boolean;
}

/**
 *
 */
const render = (props: PropsInterface): JSX.Element => {
    const basePath = `${props.routeProps.match.path}/payment-methods`
    const pushRoute = (newTab) => {
        const slug = (newTab === '' || newTab.startsWith('/')) ? newTab : `/${newTab}`
        props.routeProps.history.push(`${props.routeProps.match.path}/payment-methods${slug}`)
    }

    /**
     *
     */
    const onClickAddPaymentMethod = () => {
        pushRoute('create')
    }

    /**
     *
     */
    const afterCreateCb = () => {
        setTimeout(() => pushRoute(''), 3000)
    }

    const onChangeRoute = (r) => {
        pushRoute(r)
    }

    const onChangeTab = (tab) => {
        if (tab === 'list') {
            onChangeRoute('')
        } else {
            onChangeRoute(tab)
        }
    }
    return (
        <div className="payment-methods-wrapper" data-testid="payment-methods-app">
            <Switch className={'paymentMethodsContainer'}>
                <Route
                    path={`${basePath}/create`}
                    render={() => (
                        <PaymentMethodsCreate
                            afterCreateCb={afterCreateCb}
                            onCancel={() => onChangeRoute('')}
                            onChangeTab={onChangeTab}
                        />
                    )}
                />
                <Route
                    path={basePath}
                    render={() => (
                        <PaymentMethodsIndex
                            onClickAddPaymentMethod={onClickAddPaymentMethod}
                        />
                    )}
                />
                <Route
                    path='/'
                    render={() => <Redirect to={`${basePath}`}/>}
                />
            </Switch>

        </div>
    )
}

/**
 *
 */
export type PaymentMethodsPropsType = PropsInterface

export default render
