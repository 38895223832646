import PropTypes from 'prop-types'
import HeaderTabs from 'header-tabs'
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import { Switch, Route, Redirect } from 'react-router-dom'
import { BlockListIcon, AddPhonesTabIcon } from 'pdc-svg-icons'
import { BlockedNumbersIndex, BlockedNumbersCreate } from 'blocked-numbers-react-components'

const styles = (theme) => ({
    appWrapper: {},
    blockedNumbersContent: {
        [theme.breakpoints.up('sm')]: {
            paddingLeft: 53
        }
    }
})

class BlockedNumbers extends Component {
    constructor (props) {
        super(props)
        this.state = {
            tabs: [
                { id: 'blocked-numbers', icon: BlockListIcon, default: true },
                { id: 'block-numbers', icon: AddPhonesTabIcon, default: false }
            ],
            selectedTabId: 'blocked-numbers'
        }
    }

    get default_tab_id () {
        return this.state.tabs.find(t => t.default).id
    }

    onTabSwitch = newTab => {
        this.props.routeProps.history.push(`${this.props.routeProps.match.path}${newTab}`)
        this.setState({ selectedTabId: newTab })
    }

    onSave () {
        setTimeout(() => {
            this.onTabSwitch(this.default_tab_id)
        }, 3000)
    }

    onClickLink (route) {
        if (route.name === 'blockedCalls.create') {
            this.onTabSwitch('block-numbers')
        } else if (route.name === 'blockedCalls.index') {
            this.onTabSwitch(this.default_tab_id)
        }
    }

    render () {
        const { classes } = this.props
        const basePath = this.props.routeProps.match.path
        return (
            <div className={classes.appWrapper} data-testid='blocked-numbers'>
                <HeaderTabs smallView={this.props.smallView} tabs={this.state.tabs} onTabSwitch={this.onTabSwitch} selectedTabId={this.state.selectedTabId}/>
                <div className={classes.blockedNumbersContent}>
                    <Switch>
                        <Route path={`${basePath}blocked-numbers`} render={(props) => (<BlockedNumbersIndex onClickLink={this.onClickLink.bind(this)} showFilters={false}/>)}/>
                        <Route path={`${basePath}block-numbers`} render={() => (<BlockedNumbersCreate postSaveCb={this.onSave.bind(this)} />)}/>
                        <Route path='/' render={() => <Redirect to={`${basePath}${this.default_tab_id}`}/>}/>
                    </Switch>
                </div>

            </div>
        )
    }
}

BlockedNumbers.propTypes = {
    classes: PropTypes.object,
    extension: PropTypes.object,
    redirect: PropTypes.func.isRequired,
    routeProps: PropTypes.object,
    smallView: PropTypes.bool

}
export default withStyles(styles)(BlockedNumbers)
