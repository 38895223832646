import React, { useRef, useEffect } from 'react'
import Icon from '../Icon/Icon'
import Checkbox from '../Checkbox/Checkbox'
import style from './style'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(style)

type MenuOptions = { value: any; text?: string; title?: boolean; selected?: boolean; icon?: string; disabled?: boolean; }

interface MenuProps extends Omit<React.HTMLProps<HTMLDivElement>, 'children' | 'onChange'> {
    isOpen?: boolean
    onClose?(): any;
    options?: MenuOptions[]
    value?: any
    selectMultiple?: boolean
    onChange?(item?: MenuOptions): void
    disabled?: boolean
    withIcon?: boolean
    fullWidth?: boolean;
    'data-testid'?: string;
}

/**
 *
 */
const Menu = ({
    isOpen,
    options = [{ disabled: true, text: 'No data', value: null }],
    value,
    selectMultiple = false,
    onChange,
    disabled = false,
    withIcon,
    className,
    fullWidth = false,
    onClose,
    'data-testid': dataTestId = 'menu',
    ...props
}: MenuProps): JSX.Element => {
    const classes = useStyles({ withIcon })
    const menuRef = useRef<HTMLDivElement>(null)

    const handleClickOutside = (event: MouseEvent) => {
        if (
            menuRef.current &&
            !menuRef.current.contains(event.target as Node)
        ) {
            if (onClose) onClose()
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    return (
        <div className={classes.dropdownContainer} key={`menu-${isOpen}`} data-testid={dataTestId}>
            {isOpen && !disabled && (
                <div {...props} className={`${classes.dropdown} ${fullWidth && 'dropdown--full-width'} ${className || ''}`} ref={menuRef} data-testid="test-menu">
                    {options && options.map((item, index) => (
                        <div
                            key={item.value}
                            onClick={() => !selectMultiple && item.value !== value && onChange && onChange(item?.value)}
                            className={`${item.title ? classes.dropdownTitle : classes.dropdownItem} ${item.disabled ? classes.dropdownDisabled : ''} ${(item.value && item.value === value) ? classes.selectedItem : ''}`}
                            style={{ borderTop: item.title && index > 0 ? '1px solid #D9D8D8' : 'none', borderBottom: index === options.length - 1 ? 'none' : undefined }}
                            data-testid={`${dataTestId}-item-${index}`}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {!selectMultiple && item.icon && !item.title && (
                                    <Icon style={{ marginRight: '12px' }} name={item.icon} color={'primary'} data-testid={`${dataTestId}-icon-${index}`} size={20} />
                                )}
                                {selectMultiple && !item.title && (
                                    <div className={classes.checkbox}>
                                        <Checkbox
                                            data-testid={`${dataTestId}-checkbox-${index}`}
                                            value={item.selected}
                                            onChange={() => onChange && onChange(item?.value)}
                                            checked={item.selected}
                                            disabled={item.disabled}
                                            aria-label={item.text} // Added aria-label for accessibility
                                        />
                                    </div>
                                )}
                                {item.text}
                            </div>
                            {(item.value && item.value === value) && (
                                <div style={{ paddingRight: '10px' }}>
                                    <Icon name={'check'} color={'neutral-700'} size={20} data-testid={`${dataTestId}-selected-${index}`}/>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

/**
 *
 */
export type MenuType = MenuProps

export default Menu
